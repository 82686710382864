import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import SectionTitle from '../SectionTitle/SectionTitle';
import aboutPhoto from '../../assets/images/about/nancy-img1.png' 
import './aboutMe.css'

const AboutMe = ({ data }) => {
  return (
    <section id={data.id} data-section className="clearfix text-center">

        <div className="row clearfix about-me">

            {/* Content Left Side */}
                {/* <div className="col-3">
                    <blockquote className="testimonial text-right bigtest">
                        <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore
                            et dolore magna aliqua</q>
                        <footer>— John Doe, Happy Customer</footer>
                    </blockquote>

                </div> */}
                {/* End Content Left Side */}

                {/* Content of the Right Side */}
                <div className="col-2-3">
                    <SectionTitle 
                        title={data.title}
                        subtitle={data.subtitle}
                    />
                    {data.text.map(text => {
                        return (
                            <p key={uuidv4()}>{text}</p>
                        )
                    })}
                    {/* <p>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                        consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                        Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet!
                    </p> */}
                    {/* Just replace the Video ID "UYJ5IjBRlW8" with the ID of your video on YouTube (Found within the URL) */}
                    {/* <a href="#" data-videoid="UYJ5IjBRlW8" data-videosite="youtube" className="button video link-lightbox">
                        WATCH VIDEO <i className="fa fa-play" aria-hidden="true"></i>
                    </a> */}
                </div>
                {/* End Content Right Side */}

                <div className="col-2-3 about-img">
                    <img src={aboutPhoto} alt="" className=''/>
                </div>

            </div>


        </section>
  )
}

export default AboutMe