import React,  { useState, useEffect, useRef }  from 'react'
import { HashLink } from 'react-router-hash-link'


const Navbar = ({isMobileNavExpanded, data, setIsMobileNavExpanded}) => {
    const [activeSection, setActiveSection] = useState(null);
    const observer = useRef(null);

    useEffect(() => {
        //create new instance and pass a callback function
            observer.current = new IntersectionObserver((entries) => {
              const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
        //Update state with the visible section ID
              if (visibleSection) {
                setActiveSection(visibleSection.id);
              }
            });
        
        //Get custom attribute data-section from all sections
            const sections = document.querySelectorAll('[data-section]');
        
            sections.forEach((section) => {
              observer.current.observe(section);
            });
        //Cleanup function to remove observer
            return () => {
              sections.forEach((section) => {
                observer.current.unobserve(section);
              });

              };
    }, []);
    

    return (
        <>
            {/* Main Navigation */}
            <nav id="nav-main">
                <ul className={`clearfix ${isMobileNavExpanded ? 'expanded' : ''}`}>
                {data.map(item => {
                        return (
                            <li key={item.id}>
                                <HashLink to={`#${item.id}`} className={activeSection === item.id ? 'active' : ''}>
                                    {item.title}
                                </HashLink>    
                            </li>
                        )
                    })}
                </ul> 
            </nav>
            
            <div id="nav-trigger">
                <span className={`${isMobileNavExpanded ? 'open' : ''}`}></span>
            </div>
            <nav id="nav-mobile" style={{ display: isMobileNavExpanded ? 'block' : 'none' }}></nav>
        </>
    )
}

export default Navbar