import React, { useState } from 'react'
import Content from '../content'
import './cookies.css'


const Cookies = () => {
    const isAccepted = localStorage.getItem("cookiesAccepted");
    const [ show, setShow ] = useState(!isAccepted);


    const handleCookies = () => {
        localStorage.setItem('cookiesAccepted', true)
        setShow(false);
    };

    return (
        show && <Content classes='cookies-wrapper'>
            <div>
                <p>
                Nuestro sitio usa cookies para analizar hábitos de navegación y estadísticas. Al seguir navegando, aceptas el uso de cookies. 
                Más info en <a href='/cookies.html' className='cookies-link'>Política de Cookies</a>. 
                </p>
            </div>
            <button onClick={handleCookies} className='cookies-button button'>
                ENTENDIDO
            </button>
            {/* <a className="button">ENTENDIDO</a> */}
        </Content>
    )
}

export default Cookies