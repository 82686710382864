import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <footer id="landing-footer" className="clearfix light-winter-hazel">
            <div className='footer'>
                <p className="col-2 copyright">© 2024 Nancy Nieves. Todos los derechos reservados.</p>
            </div>
        </footer>
    )
}

export default Footer