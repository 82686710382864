import React from 'react'
import { Link } from 'react-router-dom'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Container } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './faqs.css'
import SectionTitle from '../SectionTitle/SectionTitle';

const Faqs = ({ data }) => {

    /* const questions = data.questions.filter(question => {
        if(!question.hiddenPages) return true;
        return !question.hiddenPages.includes(page)
    }) */

    return (
        <section id={data.id} data-section className='text-center faqs-section light-winter-hazel'>
            <Container>
                <SectionTitle 
                    title={data.title}
                    subtitle={data.subtitle}
                    classes={'faqs-title text-center'}
                />
                <div className='row'>
                    {data.questions.map(question => {
                        return (
                            <Accordion className='accordion-item' key={question.id}>
                                <AccordionSummary 
                                    className='faq-question' 
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    {question.title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ReactMarkdown className='faq-answer' children={question.text} linkTarget="_blank" />
                                    {question.link && <Link to={question.link} target='_blank' rel="noreferrer">{question.linkTitle}</Link>}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}  
                </div>
            </Container>
        </section>
    )
}

export default Faqs