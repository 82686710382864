import React from 'react'
import './sectionTitle.css'

const SectionTitle = ({ title, subtitle, classes}) => {
    return (
        <div className={`section-heading clearfix ${classes}`}>
            <h2 className="section-title">{title}</h2>
            <p className="section-subtitle">{subtitle}</p>
        </div>
    )
}

export default SectionTitle