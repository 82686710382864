import React from 'react'
import './banner.css'

const Banner = ({ data }) => {
    return (
        <div id="banner-wrapper">
            <div id="banner" className="row clearfix banner-wrapper">
                <div className="col-38">

                    <div className="section-heading banner banner-text">
                        <h1>{data.title}</h1>
                        <h2>{data.subtitle}</h2>
                    </div>

                    {/* Call to Action */}
                    <a href="#contacto" className="button">{data.button}</a>
                </div>
            </div>
        </div>
    )
}

export default Banner