import React from 'react';

const Input = ({type, name, id, placeholder, required, onChange}) => {
    return (
        type === 'textarea' ? (
            <textarea onChange={onChange} className='input-container' id={id} name={name} placeholder={placeholder} required={required}/>
        ) : (
            <input onChange={onChange} className='input-container'  type={type} id={id} name={name} placeholder={placeholder} required={required}/>
        )
    );
};

export default Input;