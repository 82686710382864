import React from 'react'
import './newsletter.css'
import SectionTitle from '../SectionTitle/SectionTitle'

const Newsletter = ({ data }) => {
    return (
        <section id="newsletter" className="text-center light-winter-hazel clearfix newsletter">
            <div className="">
			    <div>
                    <SectionTitle 
                        title={data.title}
                        subtitle={data.subtitle}
                        classes={'contact-heading'}
                    />
							<form className="newsletter-form" action="newsletter-contact.php" method="post" noValidate>
								<div className="form-group ">
                                    <div className='form-input'>
                                        <label className="sr-only">{data.name}</label>
                                        <input 
                                            id="newsletter-contact-name"
                                            name="name" 
                                            type="name" 
                                            className="form-control requiredField" 
                                            data-error-empty="Ingresa tu nombre" 
                                            data-error-invalid="Nombre invalido" 
                                            placeholder="Nombre"
                                        ></input>
                                    </div>

                                    <div className='form-input'>
                                        <label className="sr-only">{data.email}</label>
                                        <input 
                                                id="newsletter-contact-email"
                                                name="email" 
                                                type="email" 
                                                className="form-control requiredField" 
                                                data-error-empty="Ingresa tu E-mail" 
                                                data-error-invalid="Dirección de email invalida" 
                                                placeholder="E-mail"
                                            ></input>
                                    </div>
								</div>
                                
								<button 
                                    type="submit" 
                                    className="newsletter-button" 
                                    data-error-message="Error!" 
                                    data-sending-message="Enviando..." 
                                    data-ok-message="Mensaje enviado"
                                >
								    <i className="fa fa-arrow-right newsletter-icon "></i>
								</button>
							</form>
						</div>
					</div>


        </section>
    )
}

export default Newsletter