import React from 'react'
import logo from '../../assets/images/logo/may.svg'
import logoMini from '../../assets/images/logo/icon.svg'
import './logo.css'

export const Logo = () => {
    return (
        <div id="logo">
            {/* Logo that is shown on the banner */}
            <img src={logo} id="banner-logo" className='logo-banner' alt="Landing Page"/>
            {/* End of Banner Logo */}

            {/* The Logo that is shown on the sticky Navigation Bar */}
            <img src={logo} id="navigation-logo" className='nav-logo' alt="Landing Page"/>
            {/* End of Navigation Logo */}

        </div>
    )
}
