import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Cookies from '../../components/Cookies/Cookies';
import PageBorder from '../../components/PageBorder/PageBorder';
import ScrollUp from '../../components/ScrollUp/ScrollUp';

const Layout = () => {
    return (
        <>
            {/* <PageBorder/> */}
            <Outlet />
            {/* <WhatsappButton /> */}
            <Cookies />
            <ScrollUp />
        </>
    )
}

export default Layout