import React from 'react'
import { useState } from 'react';
import QuickContact from '../QuickContact/QuickContact';
import Input from '../Input/Input';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { v4 as uuidv4 } from 'uuid';
import './contact.css'
import Socials from '../Socials/Socials';

const Contact = ({ data }) => {
    const [ status, setStatus ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ displayForm, setDisplayForm ] = useState(true);

    const [ messageData, setMessageData ] = useState({
        name: '',
        phone: '',
        email: '', 
        message: ''
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
    
        const formData = new FormData();

        for ( var key in messageData ) {
            formData.append(key, messageData[key]);
        }        
    
    
        fetch('contact.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            console.log(response)
            setStatus({ type: response.ok ? 'success' : 'error' }) 
            setDisplayForm(response.ok ? false : true )
            setLoading(false)
          })
    };

    return (
        <section id={data.id} data-section className="text-center clearfix ">
            <div className='contact row clearfix'>

               {/*  <div classNameName="section-heading">
                    <h3>YOUR CHOICE</h3>
                    <h2 className="section-title">We have the right package for you</h2>
                </div> */}

                    <div className='section-heading contact-heading'> 
                        <h3>{data.title}</h3>
                        <h2 className='section-title'>{data.subtitle}</h2>
                    </div>

                <div className='contact-form-wrapper'>
                    <div className={'contact-form wow fadeInUp col-2-3 contact-col'}>
                            <div className='contact-intro'>
                                {data.contact.text.map(text => {
                                    return (
                                        <p key={uuidv4()}>{text}</p>
                                    )
                                })}
                            </div>
                            {displayForm === true ?
                                <div className="form-inputs-group">
                                    <form className="form-inputs" onSubmit={handleSubmit}>
                                        {data.form.map(field => {
                                            return (
                                                <Input 
                                                    key={field.name}
                                                    type={field.type} 
                                                    id={field.id} 
                                                    name={field.name} 
                                                    placeholder={field.title} 
                                                    required={field.required}
                                                    onChange={(event) => setMessageData({...messageData, [event.target.name]: event.target.value})}
                                                />
                                            )
                                        })}
                                        <button 
                                            disabled={loading}
                                            type={'submit'} 
                                            className="button button-style"
                                        >
                                            {data.buttonText}
                                        </button>
                                        {/* <Button 
                                            disabled={loading}
                                            type={'submit'} 
                                            buttonText={data.contactData.buttonText} 
                                            buttonClass={data.contactData.buttonClass}
                                        /> */}
                                    </form>
                                </div>

                                :
                                <>
                                    {status?.type === 'success' && 
                                        <Alert variant="success" >
                                            <Alert.Heading>{data.successMessage}</Alert.Heading>
                                        </Alert>
                                    }
                                </>
                            }
                            {loading &&
                                <div className='spinner-overlay'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Enviando...</span>
                                    </Spinner>
                                </div>
                            }

                            <>
                                {status?.type === 'error' && 
                                    <Alert variant='danger' className="mt-20">
                                        <p>{`${data.errorMessage}`}</p>
                                    </Alert>
                                }
                            </>


                    </div>

                    <div className='contact-info-wrapper col-2-3 contact-col'>
                        <div className='contact-info'>
                            <QuickContact
                                phone={data.contact.phone}
                                email={data.contact.email}
                                time={data.contact.time}
                            />
                            {/* <SocialMedia 
                                title={'Redes Sociales'} 
                                classes={'contact-social-icon'} 
                                outerWrapper={'icon-outer-wrapper'} 
                            /> */}
                            <h3>REDES SOCIALES</h3>
                            <Socials size='fa-2x' classes={'socials-contact'}/>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default Contact