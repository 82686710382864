import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faFacebookF,
    faInstagram,
    faYoutube,
    faTiktok,
    faXTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { 
    faHandHoldingHeart, 
    faUserGroup, 
    faHandsHoldingCircle, 
    faSeedling, 
    faHandshakeAngle, 
    faHeart, 
    faBrain, 
    faUser, 
    faBriefcase } from '@fortawesome/free-solid-svg-icons'
import './services.css'

library.add(faXTwitter, 
            faFacebookF, 
            faInstagram, 
            faYoutube, 
            faTiktok, 
            faHandHoldingHeart, 
            faHandsHoldingCircle, 
            faUserGroup, 
            faSeedling,
            faHandshakeAngle,
            faHeart,
            faBrain,
            faUser,
            faBriefcase
            )

const iconMap = {
    'faXTwitter': faXTwitter,
    'faTiktok': faTiktok,
    'faFacebookF': faFacebookF,
    'faInstagram': faInstagram,
    'faYoutube': faYoutube,
    'faHandHoldingHeart': faHandHoldingHeart,
    'faUserGroup': faUserGroup,
    'faHandsHoldingCircle': faHandsHoldingCircle,
    'faSeedling': faSeedling,
    'faHandshakeAngle': faHandshakeAngle,
    'faHeart': faHeart,
    'faBrain': faBrain,
    'faUser': faUser,
    'faBriefcase': faBriefcase
}


const Services = ({ data }) => {
    return (
        <section id={data.id} data-section className="introduction">
            {/*Introduction*/}
            <div className="row clearfix">

                <div className="col-3">
                    <div className="section-heading">
                        <h3>{data.title}</h3>
                        <h2 className="section-title">{data.subtitle}</h2>
                        <p className="section-subtitle">{data.text}</p>
                    </div>
                    <a href={data.buttonLink} target="_blank" className="button" rel="noreferrer">{data.buttonText}</a>
                </div>

                <div className="col-2-3">
                    {data.servicesList.map(service => {
                        return (
                        <div className="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.3s" key={service.id}>
                            {/* Icon */}
                            <div className="icon">
                                <i className={`fa ${service.icon} fa-2x`}></i>
                                <FontAwesomeIcon icon={iconMap[service.icon]} className={`fa fa-2x`}/>
                            </div>
                            {/*Icon Block Description */}
                            <div className="icon-block-description service-description">
                                <h4>{service.title}</h4>
                                <ul>
                                    {service.text.map(text => {
                                        return (
                                            <li>{text}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )})}

                </div>
            </div>

        </section>
    )
}

export default Services