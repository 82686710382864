import React from 'react';

const Content = ({ classes, children, id }) => {
    return (
        <div id={id} className={classes}>
            {children}
        </div>
    );
};

export default Content;