import React from 'react'
import Content from '../../components/content'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Services from '../../components/Services/Services'
import AboutMe from '../../components/AboutMe/AboutMe'
import Contact from '../../components/Contact/Contact'
import Newsletter from '../../components/Newsletter/Newsletter'
import Faqs from '../../components/Faqs/Faqs'
import Prices from '../../components/Prices/Prices'
import data from "../../data/config.json"
import Banner from '../../components/Banner/Banner'

const Home = () => {
    return (
        <Content id="" classes="">
            <Header navbar={data.navbar} banner={data.banner} />
            <Banner data={data.banner}/>
            <Services data={data.services} />
            <Newsletter data={data.newsletter} />
            <AboutMe data={data.aboutMe} />
            {/* <Prices data={data.prices} /> */}
            <Faqs data={data.faqs} />
            <Contact data={data.contactData} />
            <Footer />
        </Content>
    )
}

export default Home