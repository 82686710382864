import React, {Fragment} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import Home from './containers/Home/Home';
import './App.css';

function App() {
  return (
      <Fragment>
          <Router>
              <Routes>
                  <Route path='/' element={ <Layout/> } > {/* there is a problem using Layout */}
                      <Route index path='/' element={ <Home /> }/>
                      <Route path="*" element={<Navigate to="/" />} />
                  </Route>
              </Routes>
          </Router>
      </Fragment>
  );
}

export default App;
