import React from 'react'
import { useEffect, useState } from 'react'
import { Logo } from '../Logo/Logo'
import Banner from '../Banner/Banner'
import Socials from '../Socials/Socials'
import Navbar from '../Navbar/Navbar'
import './header.css'


const Header = ({ navbar, banner }) => {
    const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);

    useEffect(() => {
        // Responsive Navigation React logic
        const copyMainNavToMobileNav = () => {
            const navMobile = document.getElementById('nav-mobile');
            const navMain = document.getElementById('nav-main');
            if (navMobile && navMain) {
                navMobile.innerHTML = navMain.innerHTML;
            }
        };

        const handleNavTriggerClick = () => {
            setIsMobileNavExpanded(!isMobileNavExpanded);
        };

        const handleNavMobileLinkClick = () => {
            setIsMobileNavExpanded(false);
        };

        // Attach event listeners
        copyMainNavToMobileNav();
        document.querySelector('#nav-trigger span').addEventListener('click', handleNavTriggerClick);
        document.querySelectorAll('#nav-mobile ul a').forEach(link => {
            link.addEventListener('click', handleNavMobileLinkClick);
        });


        // Clean up event listeners on component unmount
        return () => {
            document.querySelector('#nav-trigger span').removeEventListener('click', handleNavTriggerClick);
            document.querySelectorAll('#nav-mobile ul a').forEach(link => {
                link.removeEventListener('click', handleNavMobileLinkClick);
            });
        };
    }, [isMobileNavExpanded]);

    return (
        <header  className="clearfix header-wrapper" data-enllax-ratio=".5">
            <div id="home" data-section ></div>
            <div id="header" className='nav-solid'>
                <div className="row clearfix header-nav">
                    <div className="col-1">

                        <Logo />
                        <Socials />

                        <Navbar 
                            isMobileNavExpanded={isMobileNavExpanded}
                            setIsMobileNavExpanded={setIsMobileNavExpanded}
                            data={navbar}
                        />

                    </div>
                </div>
            </div>

            {/* Banner Content 
            <Banner data={banner} />*/}
        </header>
    )
}

export default Header