import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './quickContact.css'

const QuickContact = ({phone, email, time}) => {
    return (
        <div className="contact-info-wrapper">
            <h3>CONTACTO</h3>
            <ul className='quick-contact'>
                {phone &&
                    <li>
                        {/* <span>Tel:</span> */}
                        <a href={'https://wa.link/33nque'} target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faWhatsapp} className="fa fa-whatsapp fa-2x fa-fw contact-icon" aria-hidden="true" />
                            {phone}
                        </a>
                    </li>
                }

                {email && 
                    <li>
                        <span>Email:</span>
                        <a href={`mailto:${email}`}>{email}</a>
                    </li>
                }

                {time && 
                    <li>
                        <span>Horario:</span>{time}
                    </li>
                }
            </ul>
        </div>
    );
};

export default QuickContact;