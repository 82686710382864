import React, { useState, useEffect } from 'react';
import './scroll.css';

const ScrollUp = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        const scrollY = window.scrollY;

        // Adjust the scroll threshold as needed
        const scrollThreshold = 100;

        setIsVisible(scrollY > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            };
    }, []);

    const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enable smooth scrolling
        });
    };

  return (
        <div
            id="scrollUp"
            className={`scroll ${isVisible ? 'visible' : 'hidden'}`}
            onClick={scrollToTop}
        >
        </div>
    );
};

export default ScrollUp;
