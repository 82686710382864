import React from 'react'
import './socialIcons.css';

const Socials = ({ size, classes }) => {
    return (
        <aside>
            {/* Social Icons in Header */}
            <ul className={`social-icons ${classes? classes : ''}`}>
                <li>
                    <a target="_blank" rel="noreferrer" title="Facebook" href="https://www.facebook.com/nancynievescoach">
                        <i className={`fa fa-facebook ${size? size : ''}`}></i><span>Facebook</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" title="Instagram" href="https://www.instagram.com/nancynievescoach/">
                        <i className={`fa fa-instagram ${size? size : ''}`}></i><span>Instagram</span>
                    </a>
                </li>
            </ul>
            {/* End of Social Icons in Header */}

        </aside>
    )
}

export default Socials